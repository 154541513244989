/* Globals
   ========================================================================== */
@import 'variables';
@import 'mixin';
@import 'place-holder';
@import 'sizes';
@import 'color';
@import 'custom-animation';
@import 'legend_map';

html {
  font-size: 62.5%;

  p{
    color: $app-primary;
  }

  button{
    &:focus{
      outline: 0 !important;
    }
  }

  svg{
    &:focus{
      outline: none !important;
    }
  }
}

//? Style général
.absolute-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horizontal-centered{
  margin-left: auto !important;
  margin-right: auto !important;
}

.paper-border {
  border: 1px solid $secondary-dark;
}

.remove-link-style {
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.error-message {
  font-size: 1.07rem;
  color: #CD4B46;
  margin: 8px 14px 0;
}

.hidden {
  display: none;
}

.title-section{
  font-size: 1.6rem;
  color: $primary-light;
  font-weight: bold;
  margin: 0 !important;
}

.result-count-text{
  color: $primary-light;
  font-style: italic;
}

.tab-active {
  background-color: white !important;
  color: black !important;
  z-index: 2;
  font-weight: bold !important;
}

.backdrop-modal {
  transition: all 0.3s ease;
  filter: blur(2px);
}

.w-30 {
  width: 30% !important;
}

.notification-success:before {
  content: '' !important;
}

//? Pour la librairie de drag&drop de widgets
.react-grid-item.react-grid-placeholder {
  background: $accent !important;
}

//? Legend des charts de Recharts
.recharts-legend-item-text {
  font-size: 1rem;
}

.recharts-text {
  font-size: 1.1rem;
}

//? Style tableau react-base-table

.BaseTable {
  box-shadow: none !important;
}

.BaseTable__table-main{
  outline: 0 !important;
}

.BaseTable__table-main .BaseTable__header{
  background-color: transparent !important;
}

.BaseTable__header{
  //position: static !important;
}

.BaseTable__header-cell{
  padding: 0 !important;
  overflow: visible !important;
  //position: static !important;
}

.BaseTable__row--hovered {
  background: transparent !important;
}

.BaseTable__row--depth-0 .BaseTable__row-cell-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select-table {
  height: 3.4rem;
  font-size: 1.4rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

//? Tree view du form customer
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: transparent !important;
}

//? Custom scrollbar si on veut qu'elle soit toujours devant
.track-horizontal{
  position: absolute;
  height: 6px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
  z-index: 999999;
}

.fat-scrollbar{
  height: 1.2rem !important;
  border-radius: 5rem;
}

.monitoring-table tr.selected:nth-child(even) {
  background: #C5E1E5 !important;
}

.monitoring-table tr.selected:nth-child(odd) {
  background: #D8EBEE !important;
}