@font-face {
  font-family: 'agproicons';
  src: url(/fonts/agproicons.woff2) format('woff2'),
    url(/fonts/agproicons.woff) format('woff'),
    url(/fonts/agproicons.ttf) format('truetype'),
    url(/fonts/agproicons.svg#agproicons) format('svg');
  font-weight: normal;
  font-style: normal;
}

.has-icon {
  margin: -3px 0.6rem 0 0.6rem;
  height: 1.6rem;
  width: 1.6rem;
}

.has-icon:before {
  font-family: 'agproicons';
  text-transform: none;
  margin: 0 0.1rem;
  font-weight: 100;
  font-size: 2rem;
  font-style: normal;
  top: 0.1rem;
  position: relative;
}

.icon-available:before {
  content: "'";
}

.icon-working::before {
  content: '0';
}

.icon-driving:before {
  content: '&';
}

.icon-break:before {
  content: '_';
}

.icon-truck:before {
  content: '8';
}

.icon-moving::before {
  content: '';
}

.icon-timer-sand::before {
  content: '';
}

.icon-settings-outline::before {
  content: '';
}

.icon-arrow-collapse:before {
  content: 'h';
}

.icon_available-color:before {
  color: #455a63;
}

.icon_working-color:before {
  color: #f77534;
}

.icon_moving-color:before {
  color: #91b837;
}

.icon_parking-color:before {
  color: #2297ff;
}

.icon_parking-color:before {
  color: #2297ff;
}

.icon_tmavat-color:before {
  color: #e9a83f;
}

.icon_tmava-color:before {
  color: #cd4b46;
}

.gmap-container {
  cursor: pointer;
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gmap-container.settings-map {
  background-color: #455a63;
}

.gmap-container.settings-map:before {
  color: #ffffff;
}

.icon-arrow-collapse:before {
  content: 'h';
}

.gmap-container.init-zoom {
  background-color: #ffffff;
}
